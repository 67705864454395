import "aos/dist/aos.css";
import "./index.scss";

const Footer = () => {
  return (
    <div className="footer-component">
      <div className="content">© UnrealTech 2021</div>
    </div>
  );
};

export default Footer;
