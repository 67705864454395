import "aos/dist/aos.css";
import { useRef } from "react";
import { useEffect } from "react";
import "./index.scss";

const Navbar = () => {
  // get the sticky element
  const scrollRef = useRef();

  const isElementInViewport = (el) => {
    if (scrollRef?.current) {
      var rect = scrollRef.current.getBoundingClientRect();

      return rect.top < 5;
    } else {
      return false;
    }
  };

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        let id = anchor.hash.slice(1);
        const yOffset = -70;
        const element = document.getElementById(id);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      });
    });

    const stickyElm = document.querySelector(".navbar-component");

    const observer = new IntersectionObserver(
      ([e]) => {
        if (isElementInViewport(stickyElm)) {
          e.target.classList?.toggle("isSticky", e.intersectionRatio < 1);
        } else {
          e.target.classList?.remove("isSticky", e.intersectionRatio < 1);
        }
      },
      { threshold: [1] },
    );

    observer?.observe(stickyElm);

    return () => {};
  }, []);

  return (
    <div className="navbar-component" ref={scrollRef}>
      <div className="content">
        <div className="logo">
          <img alt="" className="tech-logo" src="assets/unreal-tech.svg" />
        </div>
        <a className="item" href="#about">
          About
        </a>
        <a className="item" href="#demo">
          Demo
        </a>
        <a className="item" href="#projects">
          Projects
        </a>
        <a className="item" href="#contact">
          Contact us
        </a>
      </div>
    </div>
  );
};

export default Navbar;
