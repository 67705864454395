import "aos/dist/aos.css";
import IntroVideo from "../IntroVideo";
import "./index.scss";

const Header = () => {
  return (
    <div className="header-component" id="about">
      <img className="logo" alt="" src="assets/unreal-tech.svg" />
      <p className="title">
        Unreal<span>Tech</span>
      </p>
      <p className="desc">
        UnrealTech designs and builds interactive experiences that move beyond traditional single-screen moments. We work with you to create unique and exciting experiences that integrate form,
        content, interaction and the latest technology.
      </p>
      <IntroVideo url={`rGIpMHyF2VM`} size="small" header={false} />
    </div>
  );
};

export default Header;
