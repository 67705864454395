import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import "aos/dist/aos.css";
import "./index.scss";

const IntroVideo = ({ url, size, header = true, id = "vd" }) => {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    return () => {};
  }, [playing]);

  return (
    <div className="intro-video-component" id={id}>
      {header && (
        <div className="header" data-aos="fade-up" data-aos-duration="800">
          <p className="title">UnrealTech Demo</p>
          {/* <p className="desc">
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint — occaecat cupidatat non proident, sunt in culpa qui.
          </p> */}
        </div>
      )}

      <div className={["content", playing ? "playing" : "", size === "small" ? "small" : ""].join(" ")}>
        <ReactPlayer
          controls
          url={`https://www.youtube.com/watch?v=${url}&vq=hd1440`}
          id="introVideo"
          width="unset"
          height="unset"
          // class={[playing ? "playing" : ""].join(" ")}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onEnded={() => setPlaying(false)}
        />
      </div>
    </div>
  );
};

export default IntroVideo;
