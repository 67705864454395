import "aos/dist/aos.css";
import "./index.scss";

const Sections = () => {
  const sectionsData = [
    {
      icon: "",
      title: "3D Interactive Designing",
      desc: "Adding life to the daily used software on the web, and mobile, by adding immersive and engaging experiences enabling customers to interact with the presented technologies in the fields of (E-Learning, Architecture, and Automotive Design) .",
    },
    {
      icon: "",
      title: "Games Development: 2D/3D",
      desc: "Our team possesses all the skills within the spectrum, to develop games the client is targeting, on mobile, web or VR. we develop games that will exceed the delivery expectations of our client’s end users.",
    },
    {
      icon: "",
      title: "Web/Mobile 3D interactive development (WebGL)",
      desc: "We create 3D interactive interfaces for web applications with experience in targeting various industries.",
    },
    {
      icon: "",
      title: "3D Motion Graphics and Animation",
      desc: "Our creative team can innovate and deliver animation and motion graphics for any platform of the client’s interest (TV, social media, Games, and mobile applications).",
    },
    {
      icon: "",
      title: "3D Architectural Visualizations",
      desc: "We develop real-time interactive experiences for any physical products or structures such as homes, manufacturing plants and stadiums, so the client can roam around and inside to explore the best possible form of the planned product.",
    },
    {
      icon: "",
      title: "3D Cinematic Animations",
      desc: "Computer graphics rendered 3D world, with an ultra-high quality captivating and believable animations that is perfect for storytelling and showcases.",
    },
  ];

  return (
    <div className="sections-component">
      <div className="content">
        {sectionsData.map(({ icon, title, desc }, index) => (
          <div key={index} className="section" data-aos="fade-up" data-aos-delay={index > 2 ? (index - 2) * 200 : (index + 1) * 200} data-aos-duration="1000">
            <p className="title">{title}</p>
            <p className="desc">{desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sections;
