import "aos/dist/aos.css";
import useMedia from "use-media";
import "./index.scss";

const Projects = () => {
  const isMobile = useMedia({ maxWidth: "600px" });

  const projectsData = [
    {
      image: "assets/images/EduTech.png",
      title: "Nibras E-Learning Platform",
      desc: `E-Learning web platform, in which we transform the traditional hand-book educational material into a 3D fully interactive solution that provides an innovative and exciting learning experience for the new generations.`,
    },
    {
      image: "assets/images/Game_Dev_Web.png",
      title: "Game Development",
      desc: `Developing games, the client is targeting, on mobile, web or VR. we develop games that will exceed the delivery expectations of our client’s end users.`,
    },
    {
      image: "assets/images/3D_Store_Web.png",
      title: "3D Web Store",
      desc: `We provide a fully 3D interactive shopping experience, in which the user can roam in a virtual 3D store and choose the product of their interest, then complete the purchasing process in an immersive, fully interactive experience.`,
    },
    // {
    //   image: "assets/images/TV_Broadcast_Web.png",
    //   title: "3D Architectural Visualization",
    //   desc: `We developed a real-time interactive experience for a highly secured data center, so the client can roam around and inside to explore the best form of the planned project.`,
    // },
    {
      image: "assets/images/3D_Arch_Viz.png",
      title: "3D Architectural Visualization",
      desc: `We developed a real-time interactive experience for a highly secured data center, so the client can roam around and inside to explore the best form of the planned project.`,
    },
    {
      image: "assets/images/HealthCare_Dev_2.png",
      title: "Health-Care",
      cut: true,
      desc: `A project where we developed a 3D interactive interface solution to visualize the treatment plan for orthodontic malocclusions.`,
    },
    {
      image: "assets/images/HealthCare_Dev.png",
      title: "Eye Anatomy",
      cut: true,
      bottom: true,
      desc: `A health-care application for the eye anatomy visualization, where the user can view, interact, and understand the eye anatomic details.`,
    },
  ];

  return (
    <div id="projects" className="projects-component">
      <div className="header" data-aos="fade-up" data-aos-duration="800">
        <p className="title">Projects</p>
      </div>

      <div className="content">
        {isMobile
          ? projectsData?.map(({ image, title, desc, cut, bottom }, index) => {
              return (
                <div key={index} className="project" data-aos="fade-up" data-aos-duration="100">
                  <div className={["image left"]} data-aos="fade-up" data-aos-duration="400">
                    <img alt="" src={image} className={[cut ? "c" : "", bottom ? "b" : ""].join(" ")} />
                  </div>
                  <div className={"info right"} data-aos="fade-down" data-aos-duration="400">
                    <p className="title">{title}</p>
                    <p className="desc">{desc}</p>
                  </div>
                </div>
              );
            })
          : projectsData?.map(({ image, title, desc, cut, bottom }, index) => {
              let RTL = index % 2 === 0;

              return RTL ? (
                <div key={index} className="project" data-aos="fade-up" data-aos-duration="100">
                  <div className={"image left"} data-aos="fade-left" data-aos-duration="400">
                    <img alt="" src={image} className={[cut ? "c" : "", bottom ? "b" : ""].join(" ")} />
                  </div>
                  <div className={"info right"} data-aos="fade-right" data-aos-duration="400">
                    <p className="title">{title}</p>
                    <p className="desc">{desc}</p>
                  </div>
                </div>
              ) : (
                <div key={index} className="project" data-aos="fade-up" data-aos-duration="100">
                  <div className={"info left"} data-aos="fade-left" data-aos-duration="400">
                    <p className="title">{title}</p>
                    <p className="desc">{desc}</p>
                  </div>
                  <div className={"image right"} data-aos="fade-right" data-aos-duration="400">
                    <img alt="" src={image} className={[cut ? "c" : "", bottom ? "b" : ""].join(" ")} />
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Projects;
