import { useEffect } from "react";
import "./App.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./coponents/Header";
import Navbar from "./coponents/Navbar";
import Sections from "./coponents/Sections";
import Projects from "./coponents/Projects";
import IntroVideo from "./coponents/IntroVideo";
import Footer from "./coponents/Footer";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true, // whether animation should happen only once - while scrolling down
      delay: 200,
    });

    return () => {};
  }, []);

  const storeScroll = () => {
    if (window.scrollY > 500) {
      document.documentElement.dataset.scroll = 500;
    } else {
      document.documentElement.dataset.scroll = 0;
    }
  };

  useEffect(() => {
    window.onscroll = (e) => {
      // called when the window is scrolled.
      storeScroll();
    };

    storeScroll(); // first attempt

    return () => {};
  }, []);

  return (
    <div className="App">
      <div class="background-container">
        <img className="moon-bg" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/moon2.png" alt="" />
        <div class="stars"></div>
        <div class="twinkling"></div>
        <div class="clouds"></div>
      </div>

      <div className="dot one" />

      <div className="content" data-aos="fade-up" data-aos-delay="1" data-aos-duration="1000">
        <Header />
        <Navbar />

        <Sections />
        <div className="s-video-container">
          <div className="svcc">
            <IntroVideo id="demo" url={`5_yvOW1flIs`} />
          </div>
        </div>
        <Projects />

        <Footer />
      </div>
    </div>
  );
}

export default App;
